import React from 'react';
import profile from '../assets/pictures/profile_picture_transparent_copy500.png';
import LanguagesProgress from './LanguagesProgress';
import ProgrammingProgress from './ProgrammingProgress';
import './Sidebarmenu.scss';
import cv from '../assets/files/CurriculumVitae.pdf' 




function Sidebarmenu() {
  const prggr= {
        html:90,
        css:85,
        wp:85,
        js:40,
        react: 25,
        git: 40,
        php:40,
        MySQL: 40,
        bootstrap: 40,
        photoshop: 30,
        
  }

  const english = 35

  return (
    <>
              <div className='profile-block'>
                    <div className="profile-block__picture">
                      <img src={profile} alt='profile_picture' />  

                    </div>

                <div className='profile-block__picture_content'>
                    <p>Maroš Revický</p>
                    <span>Web Developer</span>
                  </div>  



<div className='profile-block__post-content'>
                      <div>
                          <h3 className='profile-block__header'>Kontaktné údaje</h3>
                          <div className='profile-block__contacts'><i className="fa-solid fa-at icon"></i><a href="mailto:m.revicky@gmail.com">m.revicky@gmail.com</a></div>
                          <div className='profile-block__contacts'><i className="fa-solid fa-square-phone icon"></i><a href="tel:+421907045763">+421 907 045 763</a></div>
                          <div className='profile-block__contacts'>
                              <div className="social_menu">
                                  <a href="https://www.facebook.com/m.revicky/" title='Môj Facebook'><i className="fa-brands fa-facebook-f"></i></a>
                                  <a href="https://www.instagram.com/maros_revicky/" title='Môj Instagram'><i className="fa-brands fa-instagram"></i></a>
                                  <a href="https://www.linkedin.com/in/maro%C5%A1-revick%C3%BD-787a9797/" title='Môj Linkedin'><i className="fa-brands fa-linkedin"></i></a>
                                </div>
                          </div>
                      </div>

                      <hr />

                      <div className="profile-block-experiences">
                          <h3 className="profile-block__header">Znalosti</h3>
                          
<ProgrammingProgress prggr={prggr} />
                      </div>

<hr />

                      <div className='profile-block__languages'>
                      <h3 className="profile-block__header">Jazyky</h3>
<LanguagesProgress eng={english} />
                      </div>

<hr />
                      <div>
                          <h3 className="profile-block__header">Záujmy a koníčky</h3>
                          <p className="profile-block-hobbies__content">rodina, IT, príroda, turistika</p>
                      </div>

</div>
              <div className="download_cv">
                    <a href={cv} title='Stiahnuť pdf' download="curriculimVitae"><i className="fa-solid fa-download" ></i> Stiahnuť životopis <i className="fa-solid fa-download"></i></a>
                </div> 

        </div>
</> 
  )
}

export default Sidebarmenu