import React from 'react';
import './MyProjects.scss';

// PICTURES
import petersimo_view from '../assets/pictures/petersimo_view.png';
import crosswordpuzzle_view from '../assets/pictures/crosswordpuzzle.png';
import curiculimvitae_view from '../assets/pictures/cv.png';
import webcodex_view from '../assets/pictures/webcodex_image.png';
import crm_view from '../assets/pictures/crm.png';
import services_work from '../assets/pictures/services_work.png';
import okmetallbau from '../assets/pictures/okmetallbau.png';
import webb from '../assets/pictures/webb.png';
import penzionbojnice from '../assets/pictures/penzionbojnice.png';




function MyProjects() {
  return (
    <main>
    <div className="projects project-block">
        <h3 className='block-header'>Moje projekty</h3>

          <div className="project-block__first">

              <h4 className='project-block__postheader'>1. Stolár Peter Šimo</h4>
              <p className='project-block__content'>Tento prezentačný web vytvorený pre stolára, bol spočiatku vyhotovený iba v HTML a CSS. Neskôr som sa rozhodol web prerobiť do PHP a doplniť nové funkcionality. Web je plne respozívny, prispôsobený pre tablety, smartfóny a pre zariadenia s menším rozlíšením. Web obsahuje kontaktný formulár, ktorý je plne fuknčný, odosiela výsledky formulára do MySQL databázy, do emailu adresáta a taktiež posiela odosielateľovi formulára odpoveď o prijatí emailu. Email o prijatí správy je nadizajnovaný a graficky upravený tak, aby bol v symbióze so samotným dizajnom webu. Obsahuje galériu, ktorá je pre lepšiu prehľadnosť rozdelená do sekcií podľa toho, aké produkty obsahuje. K dokončeniu mi chýba najmä textácia a obsahové naplnenie webu, preto nie je stránka ani spustená.</p>
                        <div className='project-block__address'><img src={petersimo_view} className='project-block__image' alt="peter_simo_web_view"/>
                            <div className='clickhere'>
                              <a href="https://marosrevicky.sk/petersimo/index.php" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
                            </div>
                        </div>
              
          </div>

          <div className="project-block__second">
              <h4 className='project-block__postheader'>2. Osemsmerovka</h4>
              <p className='project-block__content'>Inšpiroval som sa hrou Wordle, ktorá sa stala celosvetovo známa. Na základe jej úspechu som sa rozhodol vytvoriť klasickú osemsmerovku, na ktorej sa zdokonalujem vo vedomostiach v klasickom Javascripte, bez použitia frameworku. Projekt je dokončený na 30%, je spravená plocha, ktorá obsahuje slovíčka a náhodné písmena, sú vypísané slová, ktoré sa po spustení hry doplnia do tabulky. K dokončeniu chýba dať tomu logiku, aby slová rozhádzalo náhodne po hracej ploche a nie za sebou, doprogramovať označovanie písmen a zároveň mazanie slovíčok po vyznační jedného z hľadaných. 
              K úplnému dokončeniu chýba dať tomu väčšiu logiku, t.j. obmedziť výber políčok na horizontálne, vertikálne a diagonálne v oboch smeroch a taktiež pridať logiku rozmiestnenia písmen.
              </p>
                     <div className='project-block__address'><img src={crosswordpuzzle_view} className='project-block__image' alt="crossword_puzzle_view"/>
                        <div className='clickhere'>
                          
                          <a href="https://marosrevicky.sk/osemsmerovka/index.html" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Rozpracovanosť 75%</span></a>
                        </div>
                     </div>     
          </div>

          <div className="project-block__third">
              <h4 className='project-block__postheader'>3. Životopis</h4>
              <p className='project-block__content'>Doposiaľ môj najväčší projekt. Je to prezentačný web, resp. Single - page aplikácia, ktorá vystihuje a promuje mňa. Nachádza sa tu všetko o mne, čo robím a čomu som sa venoval, čo viem, čo mám vyštudované, aké mam skúsenosti, aké záujmy a kam sa chcem dostať. O to viac som pyšný na to, že web je kompletne spracovaný v React, plne funkčný po každej jednej stránke, či je to refresh stránky na podkategórii webu, či je to kontaktný formulár alebo animácie. Webu nechýba responzivita, je plne robený v Grid a Flexbox, dizajnovo naštýlovaný v SCSS. Je rozdelený do viac kategórii. Vzhľadom na to, že je to Single - page aplikácia, nepotrebuje refresh a obsah sa načítava okamžite. Web je funkčný aj po backend stránke, formulár odosiela do MongoDB databázy a do emailu. Funkčné to je na localhoste, na verejnej verzii je urobené odosielanie emailu cez plugin EmailJS.</p>
                      <div className='project-block__address'><img src={curiculimvitae_view} className='project-block__image' alt="crossword_puzzle_view"/>
                          <div className="clickhere">
                            <a href="https://marosrevicky.sk/" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
                          </div>
                      </div> 
          </div> 

          <div className="project-block__four">
              <h4 className='project-block__postheader'>4. Webcodex.sk</h4>
              <p className='project-block__content'>Webstránka vyhotovená na sebaprezentovanie mojich zručností s prácou vo Wordpress, buildrom Elementor, v ktorom bol web pôvodne vyhotovený a následne preprogramovanie do buildra Oxygen. Optimalizácia webu pre Google vyhľadávač, jednoduchá optimalizácia SEO za pomoci nástroja YoastSEO. Dôraz som kladol na rýchlosť načítavania a štruktúru kódu, čo sa mi aj podarilo docieliť a GTMetrix môj web vyhodnotil ratingom A. </p>
          <div className='project-block__address'><img src={webcodex_view} className='project-block__image' alt="webcodex_web_view"/>
              <div className='clickhere'>
                <a href="https://webcodex.sk" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
              </div>
          </div>

        </div>

        <div className="project-block__five">
                      <h4 className='project-block__postheader'>5. Email system</h4>
                      <p className='project-block__content'>Myšlienkou systému bolo urobiť riešenie, v ktorom po odoslaní webového kontaktného formuláru sa odošle email adresátovi a zároveň by bolo možné všetky emaily riešiť z jednej stránky. V tomto systéme je možné automaticky odpovedať na prichádzajúce emaily, ukončovať konverzácie na základe ktorých budete vedieť, že ste už na danú požiadavku/task klienta odpovedali/uzavreli, archivovať ich, prípadne sa k nej spätne vrátiť a pokračovať v konverzácii. Na webe sú využité technológie PHP, JS a MySQL. Email systém automaticky kontroluje prijatú poštu bez refreshu, prípadne vyhľadáva emailové adresy použitím AJAX, meni stavy poziadaviek v databáze, čerpá údaje z databázy a pod. Toto je ukončená prvá fáza, ktorej cieľom nebol dizajn ani plná responzivita, išlo primárne o funkčnosť. Po funkčnej stránke mám v pláne dokončiť prihlasovanie do admin systému, spraviť vyhľadávač emailov, zgrupovanie správ od rovnakého klienta. Triedenie a vyberanie emailov podľa dátumov z kalendára pre lepšiu orientáciu. Po dizajnovej stránke sprehladnenie, lepšie grafické zladenie, zmena svetlej a tmavej témy.</p>
                  <div className='project-block__address'><img src={crm_view} className='project-block__image' alt="webcodex_web_view"/>
                      <div className='clickhere'>
                        <a href="https://marosrevicky.sk/crm" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené, 1. fáza</span></a>
                      </div>
                  </div>

        </div>

        <div className="project-block__six">
                      <h4 className='project-block__postheader'>6. Ok-metallbau.sk</h4>
                      <p className='project-block__content'>Kompletná tvorba prezentačnej webovej stránky vo Wordpress. Klient potreboval jednoduché spravovanie webu a najmä konfigurátor brán kde by hneď do emailu prišla požiadavka na bránu rôznych parametrov a ktorú može okamžite naceniť. Stránka je vyrobená  v dvoch jazykových mutáciach.</p>
                  <div className='project-block__address'><img src={okmetallbau} className='project-block__image' alt="webcodex_web_view"/>
                      <div className='clickhere'>
                        <a href="https://ok-metallbau.sk" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
                      </div>
                  </div>

        </div>

        <div className="project-block__seven">
                      <h4 className='project-block__postheader'>7. Penzión Bojnice</h4>
                      <p className='project-block__content'>Kompletná tvorba novej webstránky na základe dodržaných a zaužívaných postupov s prihliadnutím na SEO. Na stránke sa spravil kompletný dizajn, grafika (okrem loga), programovanie, SEO a optimalizácia. Cieľom bolo dodržať nastavené trendy a systémy z pôvodnej stránky. Stránka vyhotovená vo Wordpress pre jednoduchšiu správa a obsluhu.</p>
                  <div className='project-block__address'><img src={penzionbojnice} className='project-block__image' alt="webcodex_web_view"/>
                      <div className='clickhere'>
                        <a href="https://penzionbojnice.sk" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
                      </div>
                  </div>

        </div>

        <div className="project-block__eight">
                      <h4 className='project-block__postheader'>8. English - Webb</h4>
                      <p className='project-block__content'>Klient potreboval redizajn svojej existujúcej stránky, mal požiadavky na konkrétne farby, ktoré som sa snažil dodržať. Logo je tvorba klienta. Stránka je hotová, ešte ale nie je spustená na plnej prevádzky, budú sa robiť menšie úpravy textu a obrázkov, na ktoré čakám od klienta a následne budú zapracované. V princípe je stránka hotová, bude sa následne prekladať aj do anglickej jazykovej mutácie. Klient nemal potrebu riešiť SEO, išlo len o jednoduché nastavenie, vyhľadateľnosť na Google nebola pre neho podstatná, má stálu klientelu.</p>
                  <div className='project-block__address'><img src={webb} className='project-block__image' alt="webcodex_web_view"/>
                      <div className='clickhere'>
                        <a href="https://webcodex.sk/webb/" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
                      </div>
                  </div>

        </div>

        <div className="project-block__nine">
                      <h4 className='project-block__postheader'>9. Servisné úpravy na existujúcich weboch</h4>
                      <p className='project-block__content'>Doprogramovanie do existujúcich webov, ktoré prvotne neboli mnou vyrobené, ale bol som oslovený na dorobenie pár funkcionalít, zväžša úpravy dizajnu, responzivity, kontaktných formulárov, aktualizáciu wordpressu, eliminovanie spamu a pod.</p>
                  <div className='project-block__address'><img src={services_work} className='project-block__image' alt="webcodex_web_view"/>
                      <div className='clickhere'>
                        <a href="https://webcodex.sk" className='clickhere-link'>Otvoriť stránku<br /><br /><span>Dokončené</span></a>
                      </div>
                  </div>

        </div>




    </div>
  </main>  
  )
}

export default MyProjects