import React, { useState, useEffect } from 'react';
import './ScrollToTop.scss';


function ScrollToTop() {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
  return (
    <div className="top-to-btm">
    {" "}
    {showTopBtn && (
        <div className="topUp" onClick={goToTop}><i className="fa-solid fa-arrow-up"></i></div>
    )}{" "}
</div>
  )
}

export default ScrollToTop