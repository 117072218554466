import React from 'react';
import './Home.scss';
import BackgroundPicture from '../assets/pictures/background.jpg';
import Typewriter from 'typewriter-effect';
import headerPicture from '../assets/pictures/profile_picture_transparent_copy500.png';


function Home() {
  return (
    <>
      {/* <h3 className='block-header'>Vitajte</h3> */}
        <header className='headerBlock'>
              <img src={BackgroundPicture} alt="Background picture" className='backgroundHeader'/>


                <div className='transparent'></div>
                <img src={headerPicture} alt="profile_picture" className='headerPicture' /> 
                <h1 className='homepageWelcome'>Prevediem vás <br />mojim životopisom</h1> 

            <div className='typing'>
                <span> &lt; </span> 
                    <i>code</i>    
                <span> &gt; </span> 
                
              <Typewriter 
                      options={{
                        strings: ['Ahojte', 'volám sa Maroš', 'a pracujem ako', 'Front - End Developer'],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 50
                      }}
                    /> 
                          
                <span> &lt;/ </span> 
                    <i>code</i> 
                <span> &gt; </span>

            </div> 
          
      </header>

      <section className='welcomeBlock_postheader'>
        <p>Vitajte, som veľmi rád, že ste navštívili moju stránku, ktorá je zameraná na prezentovanie mojich pracovných skúseností, vedomostí a predovšetkým programovacích zručností. Mojím cieľom je predostrieť vám viac informácií o mne, prečo som sa rozhodol zmeniť pracovnú sféru a začal sa vzdelávať v programovaní, aká cesta ma viedla k tomu, aby som sa posunul v živote ďalej a naučil sa zručnosti 21 storočia, ktoré v dnešnom svete považujem za absolútne nevyhnutné, taktiež aké sú moje silné a slabé stránky, a v neposlednom rade - prečo som vhodný kandidát na spoluprácu a mnoho iného... Ak vás ešte úvodný text neodradil, pokračujte prosím ďalej na sekciu "O mne".</p>
      </section>

</>
  )
}

export default Home