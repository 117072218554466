import React from 'react'
import './App.scss';
import TheNavigation from './components/TheNavigation';
import {Routes, Route} from 'react-router-dom';
import './assets/css/all.css';
import Sidebarmenu from './components/Sidebarmenu';
import Home from './views/Home';
import AboutMe from './views/AboutMe';
import Contact from './views/Contact';
import MyCV from './views/MyCV';
import MyProjects from './views/MyProjects';
import NotFound from './404';
import ScrollToTop from './components/ScrollToTop';
import cv from '../../frontend/src/assets/files/CurriculumVitae.pdf';


function App() {

  
  return (
<>
    {/* <Spinner /> */}
    <div className="container">
      {/* TOTO JE HEADER CELEJ STRANKY SPOLU S NAVIGACIOU. ESTE CAKA NA SPRACOVANIE AKO KOMPONENT */}
      <div className="header">
      
          <header>
                <div><TheNavigation /></div>    
          </header>

         
      </div>     

{/* TOTO JE HLAVNE MENU, ESTE CAKA NA SPRACOVANIE AKO KOMPONENT */}

          <main className='main'>
            <div className="page">
                <ScrollToTop /> 
                
              <Routes>
                <Route path="/" element={<Home /> } />
                <Route path="/AboutMe" element={<AboutMe />} />
                <Route path="/MyCV" element={<MyCV />} />
                <Route path="/MyProjects" element={<MyProjects />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path='*' element={<NotFound />}/>
                
            </Routes>
        </div>        
          </main>     


{/* !!! !!! DONE!!!*/}
          <div className="sidebar">
              <Sidebarmenu />
            </div>

{/* !!! DONE!!! */}
            <div className="footer">
              <footer className='footer-block'>                
                    <a href={cv} className="download_cv_cv" title='Stiahnuť životopis v PDF' download="CurriculimVitae">Stiahnuť CV<i className="fa-solid fa-download" ></i></a>
                  <p>Created by |<a href="https://marosrevicky.sk"> Maroš Revický </a></p>
              </footer>
              
            </div>  

    </div>
</>

  );
}

export default App;

