import React from 'react'
import './ProgrammingProgress'
import 'animate.css';
import ProgressBar from 'react-bootstrap/ProgressBar';



function ProgrammingProgress(props) {
  function HTMLProgress() {       
    return <ProgressBar now={props.prggr.html} title='Pokročilý' />;
}

  function CSSProgress() {      
    return <ProgressBar now={props.prggr.css} title='Pokročilý'  />;
}

function WPProgress() {           
  return <ProgressBar now={props.prggr.wp} title='Pokročilý'  />;
}

function JSProgress() {          
  return <ProgressBar now={props.prggr.js} title='Mierne pokročilý'  />;
}

function ReactProgress() {          
  return <ProgressBar now={props.prggr.react} title='Začiatočník'  />;
}

function PHPProgress() {           
  return <ProgressBar now={props.prggr.php} title='Mierne pokročilý'  />;
}

function GitProgress() {           
  return <ProgressBar now={props.prggr.git} title='Mierne pokročilý'  />;
}

function PhotoshopProgress() {           
  return <ProgressBar now={props.prggr.photoshop} title='Mierne pokročilý'  />;
}

function MySQLProgress() {           
  return <ProgressBar now={props.prggr.MySQL} title='Mierne pokročilý'  />;
}

function BootstrapProgress() {           
  return <ProgressBar now={props.prggr.MySQL} title='Mierne pokročilý'  />;
}
  return (
       
<div className="sidebar-programmingProgress">   
          <div className="container__progressbars">
              <div className='progressbar_line_header'>
                <h5 className='profile-block__header-languages'>HTML</h5>
                <span>{props.prggr.html + '%'}</span>  
              </div>
                     

    <HTMLProgress />
                   
          </div>
        

          {/* <hr className='borderLine' /> */}
          
              <div className="container__progressbars">
                <div className='progressbar_line_header'>
                  <h5 className='profile-block__header-languages'>CSS / SCSS</h5>
                    <span>{props.prggr.css + '%'}</span>  
                </div>
     <CSSProgress />
              </div>

            <div className="container__progressbars">
              <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>Wordpress</h5>
                <span>{props.prggr.wp + '%'}</span> 
          </div> 
         <WPProgress />
        </div>
          
          {/* <hr className='borderLine' /> */}

        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>Javascript</h5>
                <span>{props.prggr.js + '%'}</span> 
          </div> 
         <JSProgress />     
        </div>

        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>PHP</h5>
                <span>{props.prggr.php + '%'}</span> 
          </div> 
         <PHPProgress />
        </div>

        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>MySQL</h5>
                <span>{props.prggr.MySQL + '%'}</span> 
          </div> 
         <MySQLProgress />
        </div>


        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>Bootstrap</h5>
                <span>{props.prggr.bootstrap + '%'}</span> 
          </div> 
         <BootstrapProgress />
        </div>


        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>Git</h5>
                <span>{props.prggr.git + '%'}</span> 
          </div> 
         <GitProgress />
        </div>

        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>Adobe Photoshop</h5>
                <span>{props.prggr.photoshop + '%'}</span> 
          </div> 
         <PhotoshopProgress />
        </div>

        <div className="container__progressbars">
          <div className='progressbar_line_header'>
              <h5 className='profile-block__header-languages'>React</h5>
                <span>{props.prggr.react + '%'}</span> 
          </div> 
         <ReactProgress />     
        </div>



            {/* <hr className='borderLine' /> */}
</div>

  )
}

export default ProgrammingProgress



