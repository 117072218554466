import React from 'react';
import './About.scss';
import hoverPicture from '../assets/pictures/myenglish.jpeg';


function AboutMe() {
  return (
    <>
      <h3 className="block-header">Niečo o mne</h3>
      <p className='aboutmeWelcome'>Ahojte, volám sa Maroš, mám 33 rokov a som programátor. <br /><br />
Cesta k programovaniu bola kľukatá a tŕnistá, viedla rôznymi zákutiami, ale výsledok sa nakoniec dostavil. Už ako malý chlapec som inklinoval k počítačom, začalo to hraním hier, single aj multiplayer, postupne to pokračovalo záujmom o hardware, t.j. rozoberanie a mechanické opravovanie PC, výmeny komponentov, čistenie a robenie diagnostiky, aby som nakoniec skončil na pozícii programátora. <br /><br />
K programovaniu ako takému som prvýkrát pričuchol až keď som mal 30 rokov. Nebolo jednoduché začať, veľmi ťažko sa dal skĺbiť osemhodinový pracovný čas a čas vyhradený na učenie, avšak zlom nastal v období Covidu, ked sme boli všetci zavretí doma. Díky Igor. Vtedy nebol priestor na výhovorky a naplno som sa do toho obul. Okamžite som zistil, že toto je cesta ktorou by som sa chcel v živote uberať a utvrdilo ma to v názore, keď moja polovička pracujúca v IT firme nemala v tomto náročnom období núdzu o prácu, skôr naopak. Skrátka Covid-NeCovid, práca bola stále, dalo sa pracovať a zarábať aj z domu, čo ma maximálne motivovalo pohnúť sa dopredu a niečo v tom mojom živote zmeniť. <br /><br />
Motivácia ma hnala vpred pokračovať v štúdiu programovania aj po skončení obdobia covidových karantén. Hľadal som cesty, ako sa naďalej vzdelávať, spočiatku to bolo učenie sa večer do neskorej noci, no neskôr, po narodení syna,  som musel premýšlať, ako sa najlepšie zariadiť aby som neskončil s tým, čo ma baví, keďže ma v tom období rodičovstvo naplno vyťažilo. Rozhodol som sa striktne si stanoviť pracovný čas, aby som pokračoval v samoštúdiu, veľmi mi to uľahčili vlastné kancelárske priestory, kde som mal svoj kľud a možnosť naplno sa sústrediť. Na učenie som využíval online kurzy na Skillmea, Udemy, Javascripttutorial, veeeeeľa Google, Stackoverflow diskusií a mnoho iného. <br /><br />
Programovanie neberiem len ako prácu, v ktorej sa v budúcnosti jasne vidím, je to zároveň moja záľuba. Páči sa mi pocit, vidieť okamžitý výsledok po odvedenej práci, pôsobí to na mňa maximálne motivujúco. Rád riešim zložité prípady, pretože tie ma posúvajú profesne a odborne vyššie. O to lepší pocit mám zo seba a z odvedenej práce keď viem, že som niečo zložité vyriešil a zároveň som sa to naučil.

  <br /><br />
  Tu sú moje slabé stránky: <div className='badEnglish_block'>
                    <span>- cudzie jazyky,</span>
                    <img src={hoverPicture} className='badEnglish'></img>
                </div>
  - prílišná dôslednosť, <br />
  - perfekcionizmus
  <br /><br />
      Silné stránky: <br /> - perfekcionizmus (iný uhol pohľadu), <br /> - zodpovednosť, <br />- flexibilita, <br />- ctižiadostivosť, <br />- učenlivosť, <br />- komunikatívnosť
  <br />
      </p>
</> 
  )
}

export default AboutMe