import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import hoverPicture from '../assets/pictures/myenglish.jpeg'



function LanguagesProgress(props) {
  function ENGLISHProgress() {
        
    return <ProgressBar now={props.eng} title='Začiatočník'/>;
  }
  return (
    <div className="container__progressbars">

    <div className='progressbar_line_header'>
                      <h5 className='profile-block__header-languages'>anglický jazyk</h5>
                        <span>{props.eng + '%'}</span>  
                        
                    </div>
    <ENGLISHProgress />
                </div>
  )
}

export default LanguagesProgress