import React from 'react';
import './Contact.scss';
//import FormExample from '../components/ContactForm';
import PostFormExample from '../components/ContactForm'

function Contact() {
  
  return (
        
<>

<h3 className='block-header'>Tu ma nájdete</h3>
<div className="contactBlock">

  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d657.360770558269!2d18.62393755400865!3d48.77343075205993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714dd018c9025a7%3A0xe20c216707c40db0!2sWEBCODEX.SK!5e0!3m2!1ssk!2ssk!4v1681206103296!5m2!1ssk!2ssk" width="100%" height="450" title='mapMyOffice' className='map' ></iframe>
</div>

<hr />

<PostFormExample /> 

<hr className='hr_footer' />

 <div className='contact-block__contact'>


                   <div className="contact-block__inPhone">
                        <div className='contact-block__contacts'><i className="fa-solid fa-square-phone"></i><a href="tel:+421907045763">+421 907 045 763</a></div>
                        <div className='contact-block__contacts'><i className="fa-solid fa-at"></i><a href="mailto:m.revicky@gmail.com">m.revicky@gmail.com</a></div>
                        <div className='contact-block__contacts'><i className="fa-solid fa-location-dot"></i><a href='https://goo.gl/maps/BkFFtdSE37uXJgyp7'>Hviezdoslavova 484/9, 971 01 Prievidza</a></div>
                </div> 
</div>
</>
  )
}

export default Contact