import React, {useState, useRef} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './ContactForm.scss';
import emailjs from '@emailjs/browser';

// ================================================================= //

function TextControlsExample() {
    const [submitted, setSubmitted] = useState(false);
    
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [textmessage, setTextmessage] = useState('');   
    
    const form = useRef();


// -----ALL CONTROL FORM FUNCTIONS TOGETHER. FOR CHECK CONTACT FORM! ----- //
const allCheck = (name, phone, email, textarea) =>  {
    
   const en = document.querySelector('.contactform__button');
   const patternNum = /[\W A-Z]/i;
   const patternEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
   name = document.querySelector('[id="name"]').value;    
   phone = document.querySelector('[id="phone"]').value;
   email=document.querySelector('[id="email"]').value;
   textarea = document.querySelector('.formTextArea').value;

        if ((name.length >=3) && (!patternNum.test(phone)) && (patternEmail.test(email) && email.length > 7) && (textarea.length >= 10)) {      
            en.removeAttribute('disabled');
            return true; 
        } else {
            en.setAttribute('disabled', '');
            setSubmitted(false);
            return false               
   } 
} 

// ------------ POST REQUEST TO DATABASE AND TO EMAIL ----------- //
  const handleSubmit = async event => {
    event.preventDefault();

    const { name, phone, email, textmessage } = event.target.elements;
    let details = {
      name: name.value,
      phone: phone.value,
      email: email.value,
      textmessage: textmessage.value,
    };

        // EMAIL JS SEND EMAIL //
           emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            }); 

 

        // ------- SET MESSAGE AFTER SEND FORM --------- //
            
        setSubmitted(true);

        // --------- REMOVE INPUT VALUES AFTER SUBMIT FORM ---------- //
        setName('');
        setPhone('');
        setEmail('');
        setTextmessage('');
  };



  return (
    <>
<div className="contactformBlock">
    <Form className='contactform' ref={form} onSubmit={handleSubmit} onChange={allCheck}>
    
        <h1 className='contactform__header'>Kontaktný formulár</h1>

            <Form.Group className="mb-3" >   
                <Form.Control type="text" placeholder="Meno*" id='name' name='name' onChange={event => setName(event.target.value)} value={name} required/>
            </Form.Group>

            <Form.Group className="mb-3" >       
                <Form.Control type="phone" placeholder="Telefón (09xx)" id='phone' name='phone' onChange={event => setPhone(event.target.value)} value={phone}/>
            </Form.Group>

            <Form.Group className="mb-3" >      
                <Form.Control type="email" placeholder="Email*" id='email' name='email' onChange={event => setEmail(event.target.value)} value={email} required/>
            </Form.Group>

            <Form.Group className="mb-3 form_textarea">    
                <Form.Control as="textarea" rows={3}  placeholder='Správa*' id='textmessage' name='textmessage' className='formTextArea' onChange={event => setTextmessage(event.target.value)} value={textmessage} required/> 
            </Form.Group>
            <span className='contactform__required'>* povinné</span><br />

            <Button variant="primary" type="submit" className='contactform__button' disabled>
            Odoslať
            
            </Button>
            {submitted ? <div className='success_message' onClick={allCheck}>Správa bola úspešne odoslaná
            <div className='success_message_shadow'>Zavrieť</div>
            </div> : null}
    </Form>
    
                                       
        <div className="social_menu contact">
            <a href="https://www.facebook.com/m.revicky/"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/maros_revicky/"><i className="fa-brands fa-instagram"></i></a>
            <a href="https://www.linkedin.com/in/maro%C5%A1-revick%C3%BD-787a9797/"><i className="fa-brands fa-linkedin"></i></a>         
        </div>

</div>
</>
  )
}

export default TextControlsExample;