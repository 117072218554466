import React from 'react'
import './MyCV.scss';
import '../components/variables.scss';
import ametica from '../assets/pictures/ametica-logo.svg';
import finportal from '../assets/pictures/finportal_logo.svg';
import vub from '../assets/pictures/vub-logo-white.svg';
import tnuni from '../assets/pictures/tnuni.png';
import skillmea from '../assets/pictures/skillmeaa.png';
import jstutorial from '../assets/pictures/javascripttutorial.svg';
import webcodex from '../assets/pictures/webcodex.png';
import udemy from '../assets/pictures/udemy-logo.svg';


//SCREENSHOTS CERTIFIKATOV
import screenReact from '../assets/files/react.png';
import screenWebrebel1 from '../assets/files/webrebel1.png';
import screenWebrebel2 from '../assets/files/webrebel2.png';
import screenJavascript from '../assets/files/javascriptes6.png';
import screenGit from '../assets/files/git.png';
import screenSass from '../assets/files/sass.png';
import screenPHP from '../assets/files/php.jpg';



function MyCV() {
  const HrLine = () => (
    <hr style={{
            color: '#cecece',
            height: 1,
            opacity: 0.8
        }}
    />
);
  return (
    <>
    <h3 className='block-header'>Pracovné skúsenosti</h3>


{/* LAST WORK EXPERIENCE */}


<div className="workExperience-block">
<div className="workExperience workExperience__year">
              <span className='yearExperienceHeader'><h6>Rok</h6></span>
              <p className='workExperience workExperience__year'>08/2022 - trvá</p>
            </div>
            

          <div className="workExperience workExperience__header">
            <h6>Pracovná pozícia</h6>
            <p className='workExperience workExperience__workPosition bold'>Web Developer</p>
          </div>
          
          <div className="workExperience workExperience__company">
            <h6>Spoločnosť</h6>
            <div className='workExperience-companyBlock'>
                     <a href="https://webcodex.sk"><img src={webcodex} width= '100px' alt='webcodex'/></a>
            </div>

          </div>
          <div className="workExperience workExperience__job">
            <h6>Náplň práce</h6>
            <p className='workExperience workExperience__job'>Návrh a realizácia webov, založených predovšetkým na Wordpress základe, práca s buildrami Elementor prípadne Oxygen, optimalízácia webov, základná tvorba SEO a optimalizácia pre vyhľadávače, práca na Frontend, aj Backend.</p>

            <HrLine/>
          </div>



            <div className="workExperience workExperience__year">
              <span className='yearExperienceHeader'><h6>Rok</h6></span>
              <p className='workExperience workExperience__year'>07/2022</p>
            </div>
            

          <div className="workExperience workExperience__header">
            <h6>Pracovná pozícia</h6>
            <p className='workExperience workExperience__workPosition bold'>Front - End Developer</p>
          </div>
          
          <div className="workExperience workExperience__company">
            <h6>Spoločnosť</h6>
            <div className='workExperience-companyBlock'>
                     <a href="https://ametica.sk/"><img src={ametica} width= '100px' alt='ametica'/></a>
            </div>

          </div>
          <div className="workExperience workExperience__job">
            <h6>Náplň práce</h6>
            <p className='workExperience workExperience__job'>Výroba prezentačných webstránok a eshopov, optimalizácia kódu, tvorba moderného webu s použitím Grid a Flexbox, tvorba responzívneho webu, vytváranie Wordpress stránok na základe predlohy od grafika, využívanie Wordpress buildru Oxygen, štýlovanie kódu v SCSS a práca v JavaScripte.</p>

            <HrLine/>
          </div>

          

{/* NEXT WORK EXPERIENCE */}
          <div className="workExperience workExperience__year">
          <span className='yearExperienceHeader'><h6>Rok</h6></span>
              <p className='workExperience workExperience__year'>01/2019 - 06/2022</p>
            </div>
            

          <div className="workExperience workExperience__header">
            <h6>Pracovná pozícia</h6>
            <p className='workExperience workExperience__workPosition bold'>Finančný sprostredkovateľ</p>
          </div>
          
          <div className="workExperience workExperience__company">
            <h6>Spoločnosť</h6>
            <div className='workExperience-companyBlock'>
                <a href="https://finportal.sk/"><img src={finportal} width= '100px' alt='finportal'/></a>
              </div>

          </div>
          <div className="workExperience workExperience__job">
            <h6>Náplň práce</h6>
            <p className='workExperience workExperience__job'>Sprostredkovanie úverov, poistenia a investícií z portfólia finančných spoločností. Znižovanie mesačných výdavkov klienta a hľadanie optimálnych finančných produktov na základe jeho preferencií, finančných možností a skúsenstí s nimi, tvorba majetku a zábezpeky pre klienta v prípade vzniku neočakávaných udalostí.</p>
      
      <HrLine />
          </div>



          {/* NEXT WORK EXPERIENCE */}
          <div className="workExperience workExperience__year">
          <span className='yearExperienceHeader'><h6>Rok</h6></span>
              <p className='workExperience workExperience__year'>04/2016 - 12/2018</p>
            </div>
            

          <div className="workExperience workExperience__header">
            <h6>Pracovná pozícia</h6>
            <p className='workExperience workExperience__workPosition bold'>Hypotekárny špecialista</p>
          </div>
          
          <div className="workExperience workExperience__company">
            <h6>Spoločnosť</h6>
            <div className='workExperience-companyBlock'>
               <a href="https://vub.sk/"><img src={vub} width= '100px' alt='vub_banka'/></a>
            </div>

          </div>
          <div className="workExperience workExperience__job">
            <h6>Náplň práce</h6>
            <p className='workExperience workExperience__job'>Spracovanie, riešenie a nastavovanie hypotekárnych úverov podľa optimálnych podmienok klienta závislých od určenia hypotéky, podľa toho na čo bude úver použitý, či na kúpu, výstavbu, refinancovanie prípadne finančnú rezervu klienta. Riešenie poistenia nehnuteľnosti a domácnosti, ako súčasť hypotekárnych úverov.</p> 
      
      <HrLine />
          </div>



{/* FIRST WORK EXPERIENCE */}
          <div className="workExperience workExperience__year">
          <span className='yearExperienceHeader'><h6>Rok</h6></span>
              <p className='workExperience workExperience__year'>11/2014 - 03/2016</p>
            </div>
            

          <div className="workExperience workExperience__header">
            <h6>Pracovná pozícia</h6>
            <p className='workExperience workExperience__workPosition bold'>Poradca klienta</p>
          </div>
          
          <div className="workExperience workExperience__company">
            <h6>Spoločnosť</h6>
            <div className='workExperience-companyBlock'>
                <a href="https://vub.sk/"><img src={vub} width= '100px' alt='vub_banka'/></a>
            </div>

          </div>
          <div className="workExperience workExperience__job">
            <h6>Náplň práce</h6>
            <p className='workExperience workExperience__job'>Práca s klientom, analýza jeho potrieb a na základe toho vyhodnotenie a poskytnutie vhodného produktu z portfólia banky. Pozícia zahŕňala predaj všetkých finančných produktov banky, t.j. všetky typy úverov, poistenia, sporenia a investície. </p> 

       <HrLine />
          </div>
</div>                 
   

                          {/* VZDELANIE */}

<h3 className='block-header'>Vzdelanie</h3>

<div className="studyExperience-block">
<div className="studyExperience studyExperience__year">
<span className='yearExperienceHeader'><h6>Rok</h6></span>
   <p className='studyExperience studyExperience__year'>2009 - 2014</p>
</div>
                                  

     <div className="studyExperience studyExperience__studyPosition">
      <h6>Študijný program</h6>
         <div className='studyExperience-studyBlock'>
              <p className='studyExperience studyExperience__studyPosition bold'>Ľudské zdroje a personálny manažment</p>             
            </div>
      </div> 

 <div className="studyExperience studyExperience__company">
    <h6>Škola</h6>
      <p className='studyExperience studyExperience__company bold'>Trenčianska Univerzita A.Dubčeka v Trenčíne</p>
      <a href="https://tnuni.sk/aktuality/"><img src={tnuni} width= '100px' alt='tnuni_fsev'/></a>
  </div>


      <div className="studyExperience studyExperience__description">
           <h6>Popis</h6>
           <p className='studyExperience studyExperience__description'>Výsledkom študijného programu je schopnosť samostatne riešiť úlohy v oblasti zamestnaneckých vzťahov, politike zamestnávania a realizácie činností vo výrobných podnikoch, v štatných, verejných a neziskových organizáciách. Na základe získaných poznatkov navrhovať riešenia, poprípade riešiť vlastné projekty za účelom zabezpečenia potrebnej pracovnej sily.</p>

 <HrLine />
       </div>
</div>
                                    



{/* KURZY A CERTIFIKATY */}
                          
<h3 className='block-header'>Kurzy a certifikáty</h3>

{/* LAST course EXPERIENCE */}
<div className="courseExperience-block">

  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
        <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenPHP} className="certificate" title='Certifikát React' alt='Certifikát React'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>PHP</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://udemy.com/"><img src={udemy} width= '100px' alt='udemy.com'/></a>
    </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Tvorba CMS systému, aktívna práca s databázou, tvorba registrácie a prihlasovanie, tvorba blogov, vyhľadávača a mnoho iného. </p> 

<HrLine />
  </div>
</div>


{/* NEXT course EXPERIENCE */}
<div className="courseExperience-block">

  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
        <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenReact} className="certificate" title='Certifikát React' alt='Certifikát React'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>React</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://skillmea.sk/"><img src={skillmea} width= '100px' alt='skillmea_learn2code'/></a>
    </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Tvorba interaktívneho webu prostredníctvom knižnice React, práca s Hookmi, Routermi, JSON, API a NPM. Tvorba Single-page aplikácie.</p> 

<HrLine />
  </div>
</div>


{/* NEXT course EXPERIENCE */}
<div className="courseExperience-block">

  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
    <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenGit} className="certificate" title='Certifikát Git' alt='Certifikát Git a Github'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>Git a Github</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://skillmea.sk/"><img src={skillmea} width= '100px' alt='skillmea_learn2code'/></a>
    </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Práca so zálohovacim systémom GIT, tvorba záloh a vetví projektu, tvorba repozitárov na GitHub.</p> 

<HrLine />
  </div>
</div>    

{/* NEXT course EXPERIENCE */}
<div className="courseExperience-block">
  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
        <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenWebrebel2} className="certificate" title='Certifikát Webrebel 2' alt='Certifikát Webrebel 2'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>Webrebel 2: PHP a MySQL</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
        <div className='courseExperience-courseBlock'>
        <a href="https://skillmea.sk/"><img src={skillmea} width= '100px' alt='skillmea_learn2code'/></a>
        </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Základy práce s PHP a MySQL. Jednoduchá práca s databázou, vkladanie dát cez webformulár, selekcia dát z databázy, preposielanie dát medzi PHP a JavaScriptom cez JSON, práca s PHP Composerom a PHP packagmi.</p>
<HrLine />
  </div>
</div>

                          {/* NEXT course EXPERIENCE */}
<div className="courseExperience-block">

    

  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
        <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenJavascript} className="certificate" title='Certifikát Javascript' alt='Certifikát Javascript'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>Javascript ES6</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://skillmea.sk/"><img src={skillmea} width= '100px' alt='skillmea_learn2code'/></a>
      </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Vysvetlenie moderného Javascriptu a následná práca s ním, tvorba arrow funkcií, deštrukcia kódu, využívanie spread operátorov, práca s classmi.</p> 

<HrLine />
  </div>
</div>





                      {/* NEXT course EXPERIENCE */}
<div className="courseExperience-block">
  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <p className='courseExperience courseExperience__coursePosition bold'>JavaScript Tutorial</p>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://javascripttutorial.net/"><img src={jstutorial} width= '100px' alt='jstutorial.net'/></a>
    </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Základy Javascriptu, vysvetlenie základných pojmov ako pole, reťazec, premenné, funkcie, práca s Boolean operátormi. Riešenie úloh a praktických cvičení na prácu s Javascriptom.</p> 

<HrLine />
  </div>
</div>

                      {/* NEXT course EXPERIENCE */}
<div className="courseExperience-block">
  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
        <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenSass} className="certificate" title='Certifikát CSS a SASS' alt='Certifikát CSS a SASS'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>CSS / SASS</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://skillmea.sk/"><img src={skillmea} width= '100px' alt='skillmea_learn2code'/></a>
     </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Pokročilá práca s CSS, rozšírenie znalostí a vedomostí o moderný nástroj GRID a FLEXBOX. Vysvetlenie preprocesoru SASS (SCSS), zakomponovanie SCSS do projektu a práca s Devoloper Tools. Kompletný dizajn webu v SCSS.</p>

<HrLine />
  </div>
</div>


                      {/* FIRST course EXPERIENCE */}
<div className="courseExperience-block">

    

  <div className="courseExperience courseExperience__coursePosition">
    <h6>Názov kurzu</h6>
    <div className="courses_certificate">
        <div className='showup_certificate'>
            <i class="fa-regular fa-eye certificate_eye"></i>
            <img src={screenWebrebel1} className="certificate" title='Certifikát Webrebel 1' alt='Certifikát Webrebel 1'></img>  
        </div>
        <p className='courseExperience courseExperience__coursePosition bold'>Webrebel 1: HTML, CSS a JavaScript</p>     
    </div>
  </div>
  
  <div className="courseExperience courseExperience__company">
    <h6>Zdroj</h6>
    <div className='courseExperience-courseBlock'>
        <a href="https://skillmea.sk/"><img src={skillmea} width= '100px' alt='skillmea_learn2code'/></a>
      </div>
  </div>
  
  <div className="courseExperience courseExperience__job">
    <h6>Popis</h6>
    <p className='courseExperience courseExperience__job'>Základné vysvetlenie programovania webstránok, prvá skúsenosť s jazykom HTML a štýlovacím jazykom CSS a programovacím jazykom JavaScript. Zoznámenie sa s VS Code a práca v ňom a základná práca s konzolou.</p>
  </div>
</div>                    
   


</>
  )
}

export default MyCV