import React,{useState} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './TheNavigation.module.scss';
import {NavLink} from 'react-router-dom';
import nameLogo from '../assets/pictures/name_logo.png';


function TheNavigation() {
  const [expanded, setExpanded] = useState(false);

  
  return (
     <Navbar collapseOnSelect expand="lg" expanded={expanded}>
  
  <Container fluid>
    <a href="https://marosrevicky.sk" class="header_logo"><img src={nameLogo} alt="webcodex_logo" width= "200px"/></a>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto" navbarScroll>
          <NavLink to="/" onClick={() => setExpanded(false)}>Domov</NavLink> 
          <NavLink to="/AboutMe" onClick={() => setExpanded(false)}>O mne</NavLink>
          <NavLink to="/MyCV" onClick={() => setExpanded(false)}>Životopis</NavLink>
          <NavLink to="/MyProjects" onClick={() => setExpanded(false)}>Moje projekty</NavLink>
          <NavLink to="/Contact" onClick={() => setExpanded(false)}>Kontakt</NavLink>
        
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar> 

  )
}

export default TheNavigation